<template>
  <v-snackbar
    v-model="snackBar.show"
    :color="snackBar.config.color"
    :multi-line="snackBar.config.multi_line"
    :top="snackBar.config.top"
    :bottom="false"
    :left="false"
    :right="false"
    :timeout="snackBar.config.timeout"
  >
    <!--:timeout="snackBar.config.timeout"-->
    <v-row class="ma-0" justify="center" align="center" display="flex">
      <span :style="textColorStyle" class="textMessage">{{
        snackBar.config.message
      }}</span>
      <v-spacer></v-spacer>
      <v-btn dark text @click="closeSnackBar" v-if="!$store.state.showMenu">
        <span :style="textColorStyle"><v-icon>mdi-close</v-icon></span>
      </v-btn>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SnackBar",
  computed: {
    snackBar() {
      return this.$store.state.snackBar;
    },
    textColorStyle() {
      const colorMap = {
        success: "#388E3C", // Dark green
        error: "#D32F2F", // Dark red
        warning: "#8B8000", // Dark yellow
      };
      return { color: colorMap[this.snackBar.config.color] || "#000000" };
    },
  },
  methods: {
    ...mapActions({ closeSnack: "snackBar/hideToast" }),
    closeSnackBar() {
      this.closeSnack();
    },
    handleKeydown(event) {
      if (event.key === "Escape") {
        this.closeSnackBar(); // Close snackbar on Esc key
      }
    },
  },
  mounted() {
    // Listen for the Escape key when the component is mounted
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style scoped>
@media (max-width: 1281px) {
  .textMessage {
    font-size: 11px !important;
  }
  .v-icon.v-icon {
    font-size: 12px;
  }
}
@media (min-width: 1281px) {
  .textMessage {
    font-size: 15px !important;
  }
  .v-icon.v-icon {
    font-size: 30px;
  }
}
</style>
