export default {
  namespaced: true,
  state: {
    teacherUpdateModal: {
      type: "",
      show: false,
      teacherID: "",
    },
    addEditViewModal: {
      type: "", // add || edit || view
      show: false,
      teacherID: "",
    },
    school_id: null,
    deleteModal: {
      teacherID: "",
      Delete: false,
    },
    show: false,
    Delete: false,
  },
  getters: {
    getSchool: (state) => state.school_id,
    getShow: (state) => state.addEditViewModal.show,
    getTeacherShow: (state) => state.teacherUpdateModal.show,
    getDelete: (state) => state.deleteModal.Delete,
  },
  mutations: {
    TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.addEditViewModal.type = obj.type;
        state.addEditViewModal.teacherID = obj.id ? obj.id : "";
      }
      state.addEditViewModal.show = obj.show;
    },
    TOGGLE_SCHOOL_ID(state, obj) {
      state.school_id = obj;
    },
    TOGGLE_TEACHER_MODAL(state, obj) {
      if (obj.show) {
        state.teacherUpdateModal.type = obj.type;
        state.teacherUpdateModal.teacherID = obj.id ? obj.id : "";
      }
      state.teacherUpdateModal.show = obj.show;
    },
    TOGGLE_DELETE_MODAL(state, obj) {
      if (obj.Delete) {
        state.deleteModal.teacherID = obj.id ? obj.id : "";
      }
      state.deleteModal.Delete = obj.Delete;
    },
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
    toggleTeacher({ commit }, obj) {
      commit("TOGGLE_TEACHER_MODAL", obj);
    },
    toggleDeleteModal({ commit }, obj) {
      commit("TOGGLE_DELETE_MODAL", obj);
    },
    toggleSchool({ commit }, obj) {
      commit("TOGGLE_SCHOOL_ID", obj);
    },
  },
};
