export default {
  namespaced: true,
  state: {
    addEditViewModal: {
      type: "", // add || edit || view
      show: false,
      prizeID: "",
      tab: "",
    },
    deleteModal: {
      prizeID: "",
      prizeTypeID: "",
      Delete: false,
    },
    show: false,
    Delete: false,
  },
  getters: {
    getShow: (state) => state.addEditViewModal.show,
    getDelete: (state) => state.deleteModal.Delete,
  },
  mutations: {
    TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.addEditViewModal.type = obj.type;
        state.addEditViewModal.prizeID = obj.id ? obj.id : "";
        state.addEditViewModal.tab = obj.tab;
      }
      state.addEditViewModal.show = obj.show;
    },
    TOGGLE_DELETE_MODAL(state, obj) {
      if (obj.Delete) {
        state.deleteModal.prizeID = obj.id ? obj.id : "";
        state.deleteModal.prizeTypeID = obj.typeID ? obj.typeID : "";
      }
      state.deleteModal.Delete = obj.Delete;
    },
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
    toggleDeleteModal({ commit }, obj) {
      commit("TOGGLE_DELETE_MODAL", obj);
    },
  },
};
