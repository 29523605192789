export default {
  namespaced: true,
  state: {
    videoModal: {
      show: false,
      videoObject: {},
      src: "",
    },
  },
  getters: {
    getShow: (state) => state.videoModal.show,
  },
  mutations: {
    TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.videoModal.videoObject = obj.obj ? obj.obj : {};
        state.videoModal.src = obj.src ? obj.src : "";
      }
      state.videoModal.show = obj.show;
    },
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
  },
};
