<template>
  <div class="campaignMessage">
    <div class="content-wrapper">
      <div class="heading">
        <div>{{ $t("what_are_raising_money") }}</div>
        <div class="hLine"></div>
      </div>
      <div class="paragraphMessage">
        <div v-html="getDetailMessage"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CampaignMessage",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getCampaignDetail() {
      return this.getCampaign.campaign_detail;
    },
    getDonationPageType() {
      // console.log("Special Message:", this.getCampaign.campaign_detail);

      return this.getCampaign.campaign_detail.campaign_type;
    },
    getDetailMessage() {
      // console.log(
      //   "get campaign message:",
      //   this.getCampaign.campaign_detail.special_message
      // );
      return this.getCampaign.campaign_detail.special_message;
    },
  },

  watch: {},
  methods: {},
  mounted() {
    console.log("Mounted - Campaign Deatails:", this.getCampaign.campaign_detail);
  },
};
</script>
<style scoped>
.campaignMessage {
  justify-self: start;
  font-family: "Roboto Slab", serif;
  display: flex;
  padding-top: 5%;
  color: black;
  padding:15px;
}

.content-wrapper {
  max-width: 100vw;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading {
  font-size: 25px;
  font-weight: 600;
  color: #2c1963;
  display: flex;
  align-items: center;
  place-self: baseline;
  margin-bottom: 10px;
  font-family: "Roboto Slab", serif;
}

.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-left: 15px;
}

.paragraphMessage {
  font-size: 15px;
  max-width: 100vw;
  text-align: justify;
  font-family: "Roboto Slab", serif;
}

@media only screen and (max-width: 600px) {
  .heading {
    font-size: 1rem;
  }

  .hLine {
    width: 30px;
    height: 3px;
  }
}

</style>
