export default {
  namespaced: true,
  state: {
    addEditViewModal: {
      type: "", // add || edit || view
      show: false,
      schoolID: "",
      districtID: null,
    },
    schoolUpdateModal: {
      show: false,
      schoolID: "",
    },
    show: false,
  },
  getters: {
    getShow: (state) => state.addEditViewModal.show,
    getEditShow: (state) => state.schoolUpdateModal.show,
  },
  mutations: {
    TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.addEditViewModal.type = obj.type;
        state.addEditViewModal.schoolID = obj.id ? obj.id : "";
        state.addEditViewModal.districtID = obj.dID ? obj.dID : null;
      }
      state.addEditViewModal.show = obj.show;
    },
    UPDATE_TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.schoolUpdateModal.schoolID = obj.id ? obj.id : "";
      }
      state.schoolUpdateModal.show = obj.show;
    },
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
    toggleUpdateModal({ commit }, obj) {
      commit("UPDATE_TOGGLE_MODAL", obj);
    },
  },
};
