/**
 * Save the current breakpoint json value
 * It has all the details of window size
 */
import * as MODULE_KEYS from "@/constants/ModuleKeys";
import * as API_KEYS from "@/constants/APIKeys";

export default {
  data: () => ({}),
  computed: {
    $API_KEYS() {
      return API_KEYS;
    },
    $MODULE_KEYS() {
      return MODULE_KEYS;
    },
  },
  methods: {},
  mounted() {},
};
