export default {
  namespaced: true,
  state: {
    addEditViewModal: {
      type: "", // add || edit || view
      show: false,
      teacherID: "",
      grade: null,
      video: null,
      student: null,
    },
    deleteModal: {
      teacherID: "",
      show: false,
      grade: null,
      video: null,
    },
    show: false,
    Delete: false,
  },
  getters: {
    getShow: (state) => state.addEditViewModal.show,
    getDelete: (state) => state.deleteModal.show,
  },
  mutations: {
    TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.addEditViewModal.type = obj.type ? obj.type : "";
        state.addEditViewModal.teacherID = obj.id ? obj.id : "";
        state.addEditViewModal.grade = obj.grade ? obj.grade : null;
        state.addEditViewModal.video = obj.video ? obj.video : null;
        state.addEditViewModal.student = obj.student ? obj.student : null;
      }
      state.addEditViewModal.show = obj.show;
    },
    TOGGLE_DELETE_MODAL(state, obj) {
      if (obj.show) {
        state.deleteModal.teacherID = obj.id ? obj.id : "";
        state.deleteModal.grade = obj.grade ? obj.grade : null;
        state.deleteModal.video = obj.video ? obj.video : null;
      }
      state.deleteModal.show = obj.show;
    },
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
    toggleDeleteModal({ commit }, obj) {
      commit("TOGGLE_DELETE_MODAL", obj);
    },
  },
};
