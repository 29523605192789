export default {
  namespaced: true,
  state: {
    addEditViewModal: {
      type: "add", // add || edit || view
      show: false,
      campaignID: "",
      Delete: false,
    },
    addEditTaskModal: {
      type: "add", // add || edit || view
      show: false,
      taskID: "",
    },
    deleteSchoolResourceModal: {
      schoolResourceID: "",
      Delete: false,
    },
  },
  getters: {
    getShow: (state) => state.addEditViewModal.show,
    getDelete: (state) => state.addEditViewModal.Delete,
    getShowTask: (state) => state.addEditTaskModal.show,
    getSchoolResourceDelete: (state) => state.deleteSchoolResourceModal.Delete,
  },
  mutations: {
    TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.addEditViewModal.type = obj.type;
        state.addEditViewModal.districtID = obj.id ? obj.id : "";
      }
      state.addEditViewModal.show = obj.show;
    },
    TOGGLE_MODAL_DELETE(state, obj) {
      if (obj.Delete) {
        state.addEditViewModal.campaignID = obj.id ? obj.id : null;
      }
      state.addEditViewModal.Delete = obj.Delete;
      console.log(state.addEditViewModal.campaignID, "store");
    },
    TOGGLE_TASK_MODAL(state, obj) {
      if (obj.show) {
        state.addEditTaskModal.type = obj.type;
        state.addEditTaskModal.taskID = obj.id ? obj.id : "";
      }
      state.addEditTaskModal.show = obj.show;
    },
    TOGGLE_DELETE_SCHOOL_RESOURCE_MODAL(state, obj) {
      if (obj.Delete) {
        state.deleteSchoolResourceModal.schoolResourceID = obj.id ? obj.id : "";
      }
      state.deleteSchoolResourceModal.Delete = obj.Delete;
    },
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
    toggleModalDelete({ commit }, obj) {
      commit("TOGGLE_MODAL_DELETE", obj);
    },
    toggleTaskModal({ commit }, obj) {
      commit("TOGGLE_TASK_MODAL", obj);
    },
    toggleDeleteSchoolResourceModal({ commit }, obj) {
      commit("TOGGLE_DELETE_SCHOOL_RESOURCE_MODAL", obj);
    },
  },
};
