import { ROUTER_URL } from "../constants/urls";

var secret = 'ZYWA9{c~T4M?Btn_nf4h]ZWs@"6*=WZQ2}LvQ}';
var resetPasswordSecret = 'ZYWA9{c~T4M?Btn_nf4h]ZWs@"6*=WZQ2}LvQ}';

const authToken = {
  getAccessToken: () => {
    let token = null;
    const urlParams = new URLSearchParams(window.location.search);

    // Check if the current URL contains "reset-password"
    if (!window.location.href.includes("reset-password")) {
      if (urlParams.has("token")) {
        token = urlParams.get("token");
        // Save token only if not on reset-password page
        // console.log("fonud in url");
        localStorage.setItem("token", token);
       
      } else if (localStorage.getItem("token")) {
        token = localStorage.getItem("token");
      } else if (sessionStorage.getItem("token")) {
        token = sessionStorage.getItem("token");
      }
    } else {
      // console.log("Skipping token storage on reset-password page");
    }

    return token;
  },
  decodedToken: () => {
    const jwt = require("jwt-simple");
    try {
      const token = authToken.getAccessToken();
      const decoded = jwt.decode(token, secret, true);
  
      // Check if user is a parent and NOT on dashboard
      const userTeam = decoded.user_team;
      const currentPath = window.location.pathname;
  
      const isNotDashboard = !currentPath.includes("publicParentDashboard");
      // console.log("usertype",userTeam);
      if (userTeam === "Parent" && isNotDashboard) {
        localStorage.setItem("token", token);
        // console.log("✅ Token saved to localStorage for parent user.");
      }
      // else{
      //   localStorage.setItem("adminAccess", decoded.user_type);
      //   localStorage.setItem("subadminAccess", decoded.sub_user_type||null);
      // }
      
      return decoded;
    } catch (err) {
      // console.error("❌ Invalid token:", err.message);
      return {};
    }
  },
  decodedTokenFromString: (token) => {
    // const jwtDecode = require("jwt-decode");
    const jwt = require("jwt-simple");
    try {
      return jwt.decode(token, secret, true);
    } catch (err) {
      return {};
    }
  },
  /**
   * Save access token in local storage
   * @param token
   */
  setAccessToken: (token) => {
    localStorage.setItem("token", token);
  },
  /**
   * Remove access token from local storage
   */
  removeAccessToken: () => {
    localStorage.removeItem("token");
    localStorage.clear();
    sessionStorage.clear();
  },
  /**
   * Inspect the access token, Track the status of token
   * @returns {*}
   */
  inspectAccessToken: () => {
    if (authToken.isCorrectAccessToken()) {
      return {
        flagSession: true,
        action: {
          redirect: false,
        },
      };
    } else {
      return {
        flagSession: false,
        action: {
          redirect: true,
          page: ROUTER_URL.auth.children.login.name,
        },
      };
    }
  },
  /**
   * Returns true if token is correct.
   * @returns {boolean}
   */
  isCorrectAccessToken: () => {
    const jwt = require("jwt-simple");
    try {
      jwt.decode(authToken.getAccessToken(), secret, true);
      // Correct token
      return true;
    } catch (err) {
      // Invalid x
      return false;
    }
  },
  /**
   * Returns true if user has changed password and not logging in for first time
   * @returns {boolean}
   */
  loginForFirstTime: () => {
    return authToken.decodedToken().is_password_changed;
  },
  /**
   * Decode reset password token
   * @param token
   */
  decodedResetPasswordTokenFromString: (token) => {
    const jwt = require("jwt-simple");
    try {
      // Correct token
      return jwt.decode(token, resetPasswordSecret, true);
    } catch (err) {
      // Invalid token
      return {};
    }
  },
  /**
   * Returns true if token is correct.
   * @param token
   * @returns {boolean}
   */
  isCorrectResetPasswordToken: (token) => {
    const jwt = require("jwt-simple");
    try {
      jwt.decode(token, resetPasswordSecret, true);
      // Correct token
      return true;
    } catch (err) {
      // Invalid x
      return false;
    }
  },
  /**
   * Returns true if user.is_eblity_admin
   * @returns {boolean}
   */
  // is_eblity_admin: () => {
  //   return authToken.decodedToken().is_eblity_admin;
  // },
};
export default authToken;
