export default {
  namespaced: true,
  state: {
    addEditViewModal: {
      type: "", // add || edit || view
      show: false,
      gameID: "",
      dashboard: false,
    },
    selectGameModal: {
      show: false,
      type: "",
    },
    ticketInfoModal: {
      show: false,
    },
    selectGameDetail: {},
    addEditSponsorModal: {
      type: "",
      show: false,
      sponsorID: "",
    },
    addEditAssemblyModal: {
      type: "",
      show: false,
      assemblyID: "",
    },
    addEditAssemblyModal1: {
      type: "",
      show: false,
      assemblyID1: "",
    },
    volunteerModal: {
      type: "",
      show: false,
      volunteerID: "",
    },
    homeTeamModal: {
      type: "",
      show: false,
      teamID: "",
    },
    hotelInfoDetail: {
      type: "",
      show: false,
      hotelID: "",
    },
    noteModal: {
      type: "",
      show: false,
      noteID: "",
    },
    graphModal: {
      type: "",
      show: false,
      graphID: "",
    },
    hotelModal: {
      type: "",
      show: false,
      hotelID: "",
    },
    deleteModal: {
      gameID: "",
      Delete: false,
    },
    deleteNoteModal: {
      noteID: "",
      Delete: false,
    },
    deleteGameResourceModal: {
      gameResourceID: "",
      Delete: false,
    },
    messageModal: {
      show: false,
    },
    voucherModal: {
      type: "",
      show: false,
      voucherID: "",
    },
    previewModal: {
      type: "",
      show: false,
      resourceID: "",
    },
    introEmailModal: {
      type: "",
      show: false,
      checklistID: "",
      gameID: "",
    },
    viewmessageModal: {
      show: false,
      assemblyId: "",
    },
    viewallmessageModal: {
      show: false,
      gameId: "",
    },
    gamegraphModal: {
      show: false,
      gameId: "",
    },
    gametableModal: {
      show: false,
      gameId: "",
    },
    ticketrevenueModal: {
      show: false,
      gameId: "",
    },
    deleteschoolModal: {
      schoolId: "",
      Delete: false,
    },
    show: false,
    Delete: false,
  },
  getters: {
    getShow: (state) => state.addEditViewModal.show,
    getSelectShow: (state) => state.selectGameModal.show,
    getGame: (state) => state.selectGameDetail,
    getTicketShow: (state) => state.ticketInfoModal.show,
    getSponsor: (state) => state.addEditSponsorModal.show,
    getAssembly: (state) => state.addEditAssemblyModal.show,
    getAssembly1: (state) => state.addEditAssemblyModal1.show,
    getVolunteer: (state) => state.volunteerModal.show,
    getTeamShow: (state) => state.homeTeamModal.show,
    getHotelInfoShow: (state) => state.hotelInfoDetail.show,
    getNoteShow: (state) => state.noteModal.show,
    getGraphShow: (state) => state.graphModal.show,
    getVoucherShow: (state) => state.voucherModal.show,
    getHotel: (state) => state.hotelModal.show,
    getDelete: (state) => state.deleteModal.Delete,
    getNoteDelete: (state) => state.deleteNoteModal.Delete,
    getGameResourceDelete: (state) => state.deleteGameResourceModal.Delete,
    getPreviewShow: (state) => state.previewModal.show,
    getMessageShow: (state) => state.messageModal.show,
    getIntroEmailShow: (state) => state.introEmailModal.show,
    getViewAssemblyShow: (state) => state.viewmessageModal.show,
    getViewAllAssemblyShow: (state) => state.viewallmessageModal.show,
    getGameGraphShow: (state) => state.gamegraphModal.show,
    getGameTableShow: (state) => state.gametableModal.show,
    getTicketRevenueShow: (state) => state.ticketrevenueModal.show,
    getSchoolDelete: (state) => state.deleteschoolModal.Delete,
  },
  mutations: {
    STORE_GAME(state, obj) {
      state.selectGameDetail = obj.detail;
    },
    TOGGLE_HOTEL_INFO(state, obj) {
      if (obj.show) {
        state.hotelInfoDetail.type = obj.type ? obj.type : "";
        state.hotelInfoDetail.hotelID = obj.id ? obj.id : "";
      }
      state.hotelInfoDetail.show = obj.show;
    },
    TOGGLE_HOME_TEAM(state, obj) {
      if (obj.show) {
        state.homeTeamModal.type = obj.type;
        state.homeTeamModal.teamID = obj.id ? obj.id : "";
      }
      state.homeTeamModal.show = obj.show;
    },
    TOGGLE_SPONSOR(state, obj) {
      if (obj.show) {
        state.addEditSponsorModal.type = obj.type;
        state.addEditSponsorModal.sponsorID = obj.id ? obj.id : "";
      }
      state.addEditSponsorModal.show = obj.show;
    },
    TOGGLE_ASSEMBLY(state, obj) {
      if (obj.show) {
        state.addEditAssemblyModal.type = obj.type;
        state.addEditAssemblyModal.assemblyID = obj.id ? obj.id : "";
      }
      state.addEditAssemblyModal.show = obj.show;
    },
    TOGGLE_ASSEMBLY1(state, obj) {
      if (obj.show) {
        state.addEditAssemblyModal1.type = obj.type;
        state.addEditAssemblyModal1.assemblyID1 = obj.id ? obj.id : "";
      }
      state.addEditAssemblyModal1.show = obj.show;
    },
    TOGGLE_VOLUNTEER(state, obj) {
      if (obj.show) {
        state.volunteerModal.type = obj.type;
        state.volunteerModal.volunteerID = obj.id ? obj.id : "";
      }
      state.volunteerModal.show = obj.show;
    },
    TOGGLE_MODAL(state, obj) {
      if (obj.show) {
        state.addEditViewModal.type = obj.type;
        state.addEditViewModal.gameID = obj.id ? obj.id : "";
        state.addEditViewModal.dashboard = obj.dashboard
          ? obj.dashboard
          : false;
      }
      state.addEditViewModal.show = obj.show;
    },
    TOGGLE_SELECT_MODAL(state, obj) {
      state.selectGameModal.show = obj.show;
      state.selectGameDetail.type = obj.type ? obj.type : "";
    },
    TOGGLE_TICKET_INFO(state, obj) {
      state.ticketInfoModal.show = obj.show;
    },
    TOGGLE_HOTEL(state, obj) {
      if (obj.show) {
        state.hotelModal.type = obj.type;
        state.hotelModal.hotelID = obj.id ? obj.id : "";
      }
      state.hotelModal.show = obj.show;
    },
    TOGGLE_NOTE(state, obj) {
      if (obj.show) {
        state.noteModal.type = obj.type ? obj.type : "";
        state.noteModal.noteID = obj.id ? obj.id : "";
      }
      state.noteModal.show = obj.show;
    },
    TOGGLE_GRAPH(state, obj) {
      if (obj.show) {
        state.graphModal.type = obj.type ? obj.type : "";
        state.graphModal.graphID = obj.id ? obj.id : "";
      }
      state.graphModal.show = obj.show;
    },

    TOGGLE_VOUCHER(state, obj) {
      if (obj.show) {
        state.voucherModal.type = obj.type ? obj.type : "";
        state.voucherModal.voucherID = obj.id ? obj.id : "";
      }
      state.voucherModal.show = obj.show;
    },
    TOGGLE_DELETE_MODAL(state, obj) {
      if (obj.Delete) {
        state.deleteModal.gameID = obj.id ? obj.id : "";
      }
      state.deleteModal.Delete = obj.Delete;
    },
    TOGGLE_DELETE_NOTE_MODAL(state, obj) {
      if (obj.Delete) {
        state.deleteNoteModal.noteID = obj.id ? obj.id : "";
      }
      state.deleteNoteModal.Delete = obj.Delete;
    },
    TOGGLE_DELETE_GAME_RESOURCE_MODAL(state, obj) {
      if (obj.Delete) {
        state.deleteGameResourceModal.gameResourceID = obj.id ? obj.id : "";
      }
      state.deleteGameResourceModal.Delete = obj.Delete;
    },

    TOGGLE_MESSAGE_MODAL(state, obj) {
      state.messageModal.show = obj.show;
    },
    TOGGLE_PREVIEW(state, obj) {
      if (obj.show) {
        state.previewModal.type = obj.type ? obj.type : "";
        state.previewModal.resourceID = obj.id ? obj.id : "";
      }
      state.previewModal.show = obj.show;
    },
    TOGGLE_INTRO_EMAIL_MODAL(state, obj) {
      if (obj.show) {
        state.introEmailModal.type = obj.type ? obj.type : "";
        state.introEmailModal.checklistID = obj.checklistID
          ? obj.checklistID
          : "";
        state.introEmailModal.gameID = obj.gameID ? obj.gameID : "";
      }
      state.introEmailModal.show = obj.show;
    },
    TOGGLE_VIEW_ASSEMBLY_MODAL(state, obj) {
      if (obj.show) {
        state.viewmessageModal.type = obj.type ? obj.type : "";
        state.viewmessageModal.assemblyId = obj.id ? obj.id : "";
      }
      state.viewmessageModal.show = obj.show;
    },
    TOGGLE_VIEW_ALL_ASSEMBLY_MODAL(state, obj) {
      if (obj.show) {
        state.viewallmessageModal.type = obj.type ? obj.type : "";
        state.viewallmessageModal.gameId = obj.id ? obj.id : "";
      }
      state.viewallmessageModal.show = obj.show;
    },
    TOGGLE_GAME_GRAPH_MODAL(state, obj) {
      if (obj.show) {
        state.gamegraphModal.gameId = obj.id ? obj.id : "";
      }
      state.gamegraphModal.show = obj.show;
    },
    TOGGLE_GAME_TABLE_MODAL(state, obj) {
      if (obj.show) {
        state.gametableModal.gameId = obj.id ? obj.id : "";
      }
      state.gametableModal.show = obj.show;
    },
    TOGGLE_TICKET_REVENUE_SHARE_MODAL(state, obj) {
      if (obj.show) {
        state.ticketrevenueModal.gameId = obj.id ? obj.id : "";
      }
      state.ticketrevenueModal.show = obj.show;
    },
    TOGGLE_DELETE_SCHOOL_MODAL(state, obj) {
      if (obj.Delete) {
        state.deleteschoolModal.schoolId = obj.id ? obj.id : "";
      }
      state.deleteschoolModal.Delete = obj.Delete;
    },
  },
  actions: {
    toggleModal({ commit }, obj) {
      commit("TOGGLE_MODAL", obj);
    },
    toggleSelectModal({ commit }, obj) {
      commit("TOGGLE_SELECT_MODAL", obj);
    },
    toggleGameModal({ commit }, obj) {
      commit("STORE_GAME", obj);
    },
    toggleTicketInfo({ commit }, obj) {
      commit("TOGGLE_TICKET_INFO", obj);
    },
    toggleSponsor({ commit }, obj) {
      commit("TOGGLE_SPONSOR", obj);
    },
    toggleAssembly({ commit }, obj) {
      commit("TOGGLE_ASSEMBLY", obj);
    },
    toggleAssembly1({ commit }, obj) {
      commit("TOGGLE_ASSEMBLY1", obj);
    },
    toggleVolunteer({ commit }, obj) {
      commit("TOGGLE_VOLUNTEER", obj);
    },
    toggleHomeTeam({ commit }, obj) {
      commit("TOGGLE_HOME_TEAM", obj);
    },
    toggleHotelInfoModal({ commit }, obj) {
      commit("TOGGLE_HOTEL_INFO", obj);
    },
    toggleNoteModal({ commit }, obj) {
      commit("TOGGLE_NOTE", obj);
    },
    toggleVoucherModal({ commit }, obj) {
      commit("TOGGLE_VOUCHER", obj);
    },
    toggleDeleteModal({ commit }, obj) {
      commit("TOGGLE_DELETE_MODAL", obj);
    },
    toggleDeleteNoteModal({ commit }, obj) {
      commit("TOGGLE_DELETE_NOTE_MODAL", obj);
    },
    toggleDeleteGameResourceModal({ commit }, obj) {
      commit("TOGGLE_DELETE_GAME_RESOURCE_MODAL", obj);
    },
    toggleMessageModal({ commit }, obj) {
      commit("TOGGLE_MESSAGE_MODAL", obj);
    },
    togglePreviewModal({ commit }, obj) {
      commit("TOGGLE_PREVIEW", obj);
    },
    toggleIntroEmailModal({ commit }, obj) {
      commit("TOGGLE_INTRO_EMAIL_MODAL", obj);
    },
    toggleGraphModal({ commit }, obj) {
      commit("TOGGLE_GRAPH", obj);
    },
    toggleViewAssemblyModal({ commit }, obj) {
      commit("TOGGLE_VIEW_ASSEMBLY_MODAL", obj);
    },
    toggleAllViewAssemblyModal({ commit }, obj) {
      commit("TOGGLE_VIEW_ALL_ASSEMBLY_MODAL", obj);
    },
    toggleGameGraphModal({ commit }, obj) {
      commit("TOGGLE_GAME_GRAPH_MODAL", obj);
    },
    toggleGameTableModal({ commit }, obj) {
      commit("TOGGLE_GAME_TABLE_MODAL", obj);
    },
    toggleTicketRevenueModal({ commit }, obj) {
      commit("TOGGLE_TICKET_REVENUE_SHARE_MODAL", obj);
    },
    toggleDeleteSchoolModal({ commit }, obj) {
      commit("TOGGLE_DELETE_SCHOOL_MODAL", obj);
    },
  },
};
