import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    defaultTheme: null, 
    themes: {
      light: {
        success: "#C8E6C9", // Light green
        error: "#FFCDD2", // Light red
        warning: "#FFE082", // Light yellow
      },
    },
  },
});
