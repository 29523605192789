import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) =>
    new Promise(() => {
      const position = savedPosition || {};
      if (!savedPosition) {
        if (to.hash) {
          position.selector = to.hash;
        }
        if (to.matched.some((m) => m.meta.scrollToTop)) {
          position.x = 0;
          position.y = 0;
        }
      }
      window.scrollTo(position.x, position.y);
    }),
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "WizFit";
  next();
});

export default router;
