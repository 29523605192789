import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import authToken from "../common/authToken";
import VueMeta from "vue-meta";
// import store from "../store";

Vue.use(VueRouter);
Vue.use(VueMeta);

function firewallRoute(to, from, next) {
  const res = authToken.inspectAccessToken();
  if (!res.flagSession && res.action.redirect) {
    console.log("Navigating to:", to.path, "| isPublic:", to.meta.isPublic);
    next({ name: res.action.page }); // redirect
    return true;
  }
  return false; // everything okay
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) =>
    new Promise(() => {
      const position = savedPosition || {};
      if (!savedPosition) {
        if (to.hash) {
          position.selector = to.hash;
        }
        if (to.matched.some((m) => m.meta.scrollToTop)) {
          position.x = 0;
          position.y = 0;
        }
      }
      window.scrollTo(position.x, position.y);
    }),
});

router.beforeEach(async(to, from, next) => {
  document.title = to.meta.title || "WizFit";

  if (!to.meta.isPublic) {
    const blocked = firewallRoute(to, from, next);
    if (blocked) return; // 🔒 don't call next again
  }
  // await store.dispatch("campaign/getSelectedCampaign");
  next();
});

export default router;
